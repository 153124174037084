import newTejLogo from "../../assets/img/tejlife_SVG_logo_2.svg";
import { Link } from "react-router-dom";
import whatsappFavicon from "../../assets/img/new/whatsapp.png";
import { AndroidOutlined, AppleOutlined, InstagramFilled, LinkedinFilled, TwitterOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import RoutingLinks from "src/utils/RoutingLinks";

const FooterMain = () => {
  return (
    <>
      <footer className="footer-container">
        <div className="container-default w-container">
          <div className="footer---top">
            <div className="flex gap-2 justify-start items-end mb-14 mg-bottom-32px---tablet">
              <Link
                to={RoutingLinks.website.home}
                aria-current="page"
                className="footer-logo-wrapper !mb-0 w-inline-block w--current main-logo"
              >
                Mascru
                {/* <img
                  src={newTejLogo}
                  alt="Mascru - A tech agency - Logo"
                  className="footer-logo"
                /> */}
              </Link>
              <p className="ml-3 relative bottom-[-3px] !mb-0 text-xl font-medium">A Tech Company</p>
            </div>
            <div className="w-layout-grid grid-3-columns footer-grid-3-cols-v1">
              <div>
                <div className="w-layout-grid grid-2-columns links-container-grid-2-cols-v2 footer-link-grid">
                  <div className="w-layout-grid grid-1-column links-container-grid-1-col mt-6 md:mt-0">
                    <div className="subtitle v1">Home</div>
                    <div className="grid-1-row gap-3">
                      <Link
                        to={RoutingLinks.website.home}
                        className="link-wrapper v1 text-200 w-inline-block"
                      >
                        <div>Home</div>
                      </Link>
                      <Link
                        to={RoutingLinks.website.products}
                        className="link-wrapper v1 text-200 w-inline-block"
                      >
                        <div>Products</div>
                      </Link>
                      <Link
                        to={RoutingLinks.website.about}
                        className="link-wrapper v1 text-200 w-inline-block"
                      >
                        <div>About</div>
                      </Link>
                      <Link
                        to={RoutingLinks.website.team}
                        className="link-wrapper v1 text-200 w-inline-block"
                      >
                        <div>Team</div>
                      </Link>
                      <Link
                        to={RoutingLinks.website.contact}
                        className="link-wrapper v1 text-200 w-inline-block"
                      >
                        <div>Contact</div>
                      </Link>
                      <Link
                        to={RoutingLinks.website.careers}
                        className="link-wrapper v1 text-200 w-inline-block"
                      >
                        <div>Careers</div>
                        <Tooltip title="Work with us">
                          <div className="badge-primary smallest text-uppercase">
                            job
                          </div>
                        </Tooltip>
                      </Link>
                      <Link
                        to={RoutingLinks.website.services}
                        className="link-wrapper v1 text-200 w-inline-block"
                      >
                        <div>Services</div>
                        <Tooltip title="Enterprise Resource Planning">
                          <div className="badge-primary smallest text-uppercase">
                            ERP
                          </div>
                        </Tooltip>
                      </Link>
                    </div>
                  </div>
                  <div className="w-layout-grid grid-1-column links-container-grid-1-col mt-6 md:mt-0">
                    <div className="subtitle v1">Our Services</div>
                    <div className="grid-1-row gap-3">
                    <Link
                        to={RoutingLinks.website.services}
                        className="link-wrapper v1 text-200 w-inline-block"
                      >
                        <div>All Services</div>
                      </Link>
                      <Link
                        to={RoutingLinks.website.servicesAll.ERPsolutions}
                        className="link-wrapper v1 text-200 w-inline-block"
                      >
                        <div>ERP Solutions</div>
                      </Link>
                      <Link
                        to={RoutingLinks.website.servicesAll.healthCare}
                        className="link-wrapper v1 text-200 w-inline-block"
                      >
                        <div>Healthcare Softwares</div>
                      </Link>
                      <Link
                        to={RoutingLinks.website.servicesAll.ai}
                        className="link-wrapper v1 text-200 w-inline-block"
                      >
                        <div>Artificial Intelligence</div>
                        <Tooltip title="Machine Learning">
                          <div className="badge-primary smallest text-uppercase">
                            ML
                          </div>
                        </Tooltip>
                      </Link>
                      <Link
                        to={RoutingLinks.website.servicesAll.devops}
                        className="link-wrapper v1 text-200 w-inline-block"
                      >
                        <div>Dev-ops</div>
                        <Tooltip title="Amazon Web Services, Docker, Azure & Automation">
                          <div className="badge-primary smallest text-uppercase">
                            aws
                          </div>
                        </Tooltip>
                      </Link>
                      <Link
                        to={RoutingLinks.website.servicesAll.chatai}
                        className="link-wrapper v1 text-200 w-inline-block"
                      >
                        <div>Chatot & Voice Engine</div>
                      </Link>
                      <Link
                        to={RoutingLinks.website.servicesAll.nlp}
                        className="link-wrapper v1 text-200 w-inline-block"
                      >
                        <div>NLP Training</div>
                      </Link>
                      <Link
                        to={RoutingLinks.website.servicesAll.voiceai}
                        className="link-wrapper v1 text-200 w-inline-block"
                      >
                        <div>Voice AI Engine</div>
                      </Link>
                      <Link
                        to={RoutingLinks.website.servicesAll.manufacturing}
                        className="link-wrapper v1 text-200 w-inline-block"
                      >
                        <div>Manufacturing Industry Solutions</div>
                      </Link>
                      <Link
                        to={RoutingLinks.website.servicesAll.appdev}
                        className="link-wrapper v1 text-200 w-inline-block"
                      >
                        <div>App Development</div>
                        <Tooltip title="iOS & Android App">
                          <div className="badge-primary flex justify-between smallest text-uppercase">
                            <AppleOutlined />
                            <AndroidOutlined />
                          </div>
                        </Tooltip>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-w-id="1e24484b-4896-d475-a335-7cdc176f4fb9"
                className="inner-container _176px _100-mbl"
              >
                <div className="w-layout-grid grid-1-column links-container-grid-1-col mt-6 md:mt-0">
                  <div className="subtitle v1">Our Work</div>
                  <div className="grid-1-row gap-3">
                    <Link
                      to={RoutingLinks.website.projects.ypredict}
                      className="link-wrapper v1 text-200 w-inline-block"
                    >
                      <div>yPredict Crypto</div>
                      <Tooltip title="Crypto Prediction Tool">
                          <div className="badge-primary smallest text-uppercase">
                            AI
                          </div>
                        </Tooltip>
                    </Link>
                    <Link
                      to={RoutingLinks.website.projects.findscan}
                      className="link-wrapper v1 text-200 w-inline-block"
                    >
                      <div>Findscan Stocks</div>
                    </Link>
                    <Link
                      to={RoutingLinks.website.projects.artistsPlatform}
                      className="link-wrapper v1 text-200 w-inline-block"
                    >
                      <div>Artists Platform</div>
                    </Link>
                    <Link
                      to={RoutingLinks.website.projects.legalPlatform}
                      className="link-wrapper v1 text-200 w-inline-block"
                    >
                      <div>Legal Platform</div>
                    </Link>
                    <Link
                      to={RoutingLinks.website.projects.xdcwallet}
                      className="link-wrapper v1 text-200 w-inline-block"
                    >
                      <div>Blockchain Wallet</div>
                    </Link>
                    <Link
                      to={RoutingLinks.website.projects.drchatbot}
                      className="link-wrapper v1 text-200 w-inline-block"
                    >
                      <div>Dr. Chatbot</div>
                    </Link>
                    <Link
                      to={RoutingLinks.website.projects.mithomes}
                      className="link-wrapper v1 text-200 w-inline-block"
                    >
                      <div>MIT Homes</div>
                    </Link>
                    <Link
                      to={RoutingLinks.website.projects.ERPsolution}
                      className="link-wrapper v1 text-200 w-inline-block"
                    >
                      <div>ERP Solution</div>
                    </Link>
                    <Link
                      to={RoutingLinks.website.projects.medicalIndustry}
                      className="link-wrapper v1 text-200 w-inline-block"
                    >
                      <div>Medical Software</div>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                id="w-node-_1e24484b-4896-d475-a335-7cdc176f4fd8-91dd5701"
                className="inner-container _422px _100-tablet"
              >
                <div className="card footer-card dark:bg-slate-100 dark:border-slate-100">
                  <div className="footer-card---wrapper">
                    <div>
                      <div className="mg-bottom-12px">
                        <img
                          src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dc2160f0e3a951edb54576_smartphone-cloudly-x-webflow-template.png"
                          alt="Download Our App - Mascru - A tech agency"
                          data-w-id="1e24484b-4896-d475-a335-7cdc176f4fdd"
                          className="footer---smartphone-image"
                        />
                      </div>
                      <div className="mg-bottom-20px">
                        <div className="text-400 bold color-neutral-100">
                          Hire us
                        </div>
                      </div>
                      <div className="mg-bottom-24px">
                        <div className="inner-container _348px _100-mbl">
                          <p className="color-neutral-100">
                            Lets ⚡️ grow your business, drop us a message
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="width-100-mbp">
                      <div className="flex-vertical center gap-20px">
                        <Link
                          to="imessage://yogeshnogia@icloud.com"
                          target="_blank"
                          className="btn-primary white width-100 w-inline-block"
                          rel="noreferrer"
                        >
                          <div className="flex-horizontal gap-column-6px">
                            <img
                              src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dc2370e06cc294c742a835_apple-logo-cloudly-x-webflow-templates.svg"
                              alt=""
                            />
                            <div>iMessage us</div>
                          </div>
                        </Link>
                        <Link
                          to="whatsapp://send?text=Hello Mascru, Can we have quick chat regarding one of my Projects. Thank you!&phone=+919928382340"
                          target="_blank"
                          className="btn-primary white width-100 w-inline-block"
                          rel="noreferrer"
                        >
                          <div className="flex-horizontal gap-column-6px">
                            <img
                              src={whatsappFavicon}
                              alt=""
                              className="w-[18px]"
                            />
                            <div>Whatsapp us</div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divider footer-divider"></div>
          <div
            data-w-id="1e24484b-4896-d475-a335-7cdc176f4ff0"
            className="footer---bottom"
          >
            <div className="text-center">
              <p className="mg-bottom-0 color-neutral-700">
                Copyright © Mascru | Connect{" "}
                <Link to="mailto:hey@mascru.com">
                    hey@mascru.com
                  </Link>
              </p>
            </div>
            <div className="social-media-flex">
              <Link
                to="https://twitter.com/letstej"
                target="_blank"
                className="social-icon-square w-inline-block"
                rel="noreferrer"
              >
                <div className="social-icon-font"><TwitterOutlined /></div>
              </Link>
              <Link
                to="https://www.instagram.com/tej.life"
                target="_blank"
                className="social-icon-square w-inline-block"
                rel="noreferrer"
              >
                <div className="social-icon-font"><InstagramFilled /></div>
              </Link>
              <Link
                to="https://www.linkedin.com/company/100019238/"
                target="_blank"
                className="social-icon-square w-inline-block"
                rel="noreferrer"
              >
                <div className="social-icon-font"><LinkedinFilled /></div>
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterMain;
