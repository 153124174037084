/**
 * Basic Setting Variables Define
 */
export const BaseSetting = {
  name: 'Mascru',
  desc: 'Mascru | Best tech agency for ERP Softwares, Banking Solutions, Mutual Funds, Government Projects, Blockchain Technology, Crypto & Stock Marketplace Experts. Operating from Jaipur & Delhi, serving clients all over the globe.',
  displayName: 'My Admin Panel',
  appVersion: '1.0.0',
  frontDomain: 'tej.life',
  backendDomain: 'backend.tej.life',
  server: {
    adminApiDomain: 'SERVER_DOMAIN_NAME/api/admin',
    employeeApiDomain: 'SERVER_DOMAIN_NAME/api/employee',
    userApiDomain: 'SERVER_DOMAIN_NAME/api/user'
  },
  localApiDomain: 'http://localhost:3800/api',
  ApiDomain:'https://backend.tej.life/api',
  webApiDomain: 'https://backend.tej.life/api',
  imageDomain: 'S3 Domain URL',
  protocol: 'https',
  defaultLanguage: 'en',
  languageSupport: ['en', 'es']
};
