
import { Link } from "react-router-dom";

import TeamMembersInfo from "src/types/TeamMembersInfo";

const OurTeam = () => {
  return (
    <>
      <div className="section !py-40 bg-neutral-200">
        <div className="container-default w-container">
          <div className="inner-container _610px center md:style-1">
            <div className="text-center mg-bottom-64px">
              <h2 className="display-2">Meet our amazing team</h2>
              <p className="mg-bottom-0">
                Introducing Our Exceptional Team: Comprising Talent from
                Flipkart, Amazon, Stripe, and Merkle Inc. We take pride in being
                the epitome of a top-tier team.
              </p>
            </div>
          </div>
          <div
            data-w-id="82b41b6d-a48b-7764-d8b0-0c87a5321e49"
            className="w-dyn-list md:style-1"
          >
            <div
              role="list"
              className="grid-3-columns team-member-grid w-dyn-items"
            >
              {TeamMembersInfo.map(member => {
                return (
                  <>
                    <div role="listitem" className="w-dyn-item">
                      {/* // https://cal.com/yogeshnogia/lets-talk */}
                      <Link
                        data-w-id="615c35fe-5346-6ce7-0b51-ce9ab8aabca3"
                        to={member.link}
                        className="display-block text-decoration-none w-inline-block"
                      >
                        <div className="flex-vertical center">
                          <div
                            className="avatar-circle _07 mg-bottom-24px"
                            style={{ background: `${member.bgColor}` }}
                          >
                            <img
                              src={member.img}
                              alt={member.name}
                              sizes="(max-width: 767px) 180px, (max-width: 991px) 200px, 240px"
                              srcSet={member.img}
                              className="avatar-image"
                            />
                          </div>
                          <div className="text-center">
                            <h3 className="display-4">{member.name}</h3>
                            <div className="display-5 color-neutral-600">
                              {member.position}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurTeam;
